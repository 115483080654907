import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { ImArrowUp } from 'react-icons/im';
import { FaQuoteLeft, FaBasketballBall } from 'react-icons/fa';
import { SiInstagram } from 'react-icons/si';
import Button from '@material-ui/core/Button';
import HighlightCard from '../components/HighlightCard';
import Container from '../components/Container';
import Testimonial from '../components/Testimonial';
import Notification from '../components/Notification';
import SEO from '../components/SEO';

const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: rgb(48, 48, 48);
  background: radial-gradient(
    circle,
    rgba(48, 48, 48, 0.738532913165266) 0%,
    rgba(48, 48, 48, 1) 100%
  );

  border-top: 1px solid var(--black);
  color: var(--white);
  text-align: center;
  min-width: 100%;

  padding-bottom: 60px;

  @media (min-width: 600px) {
    align-items: center;
  }
`;

const HeadItem = styled.div`
  flex: 1;
  padding: 0 10px 20px 10px;
  font-size: 1rem;
  color: red;

  h2 {
    font-size: 4rem;
    color: var(--orange);
  }

  img {
    height: auto;
    min-width: 100%;
  }

  @media (min-width: 600px) {
    max-width: 600px;
    min-width: 600px;

    img {
      max-width: 400px;
      height: auto;
    }
  }
`;

const SectionItem = styled.div`
  padding: 10px 10px;
  white-space: pre-wrap;

  &.headerItem {
    padding: 0px;
  }

  h3 {
    font-weight: 900;
    font-size: 1.4em;
  }
  h4 {
    display: flex;
    align-items: center;
    font-size: 3rem;
  }
  p {
    padding: 10px 30px;
    color: var(--grey);
  }

  div {
    justify-content: center;
  }
  Button {
    margin-top: 10px;
    color: white;
    font-size: 1.5rem;
  }

  @media (min-width: 600px) {
    padding: 10px 50px;

    h3 {
      font-size: 6rem;
      text-align: center;
    }
  }
`;

const VideoContainer = styled.div`
  padding-top: 30px;
  width: 100%;
`;

const Video = styled.div`
  height: auto;
  min-width: 100%;
`;

const ImageWrapper = styled.div`
  border-top: 1px solid var(--black);
  padding: 10px;
  min-height: 70rem;
  background-color: var(--black);
  @media (min-width: 600px) {
    min-height: 1200px;
  }
`;

const HighlightContainer = styled.div`
  border-top: 1px solid var(--black);
  background: rgb(21, 95, 204);
  background: linear-gradient(
    180deg,
    rgba(21, 95, 204, 1) 0%,
    rgba(9, 39, 83, 1) 100%
  );

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 40px 20px;

  h1 {
    color: var(--white);
  }

  @media (min-width: 600px) {
    padding: 80px 110px;
  }
`;

const ImArrowUpStyled = styled(ImArrowUp)`
  color: var(--orange);
  font-size: 2em;
`;

const FaBasketballBallStyled = styled(FaBasketballBall)`
  color: var(--orange);
  padding-right: 10px;
`;

const FaQuoteLeftStyled = styled(FaQuoteLeft)`
  color: var(--orange);
  font-size: 2em;
`;

export default function Home({ data }) {
  const siteSettings = data.settings.nodes[0];
  const sectionItems = data.sectionItems.nodes;
  const highlightItems = data.highlightItems.nodes;
  const testimonials = data.testimonials.nodes;
  const notifications = data.notifications.nodes;

  // Get random number for the image to return
  const randomImage = Math.floor(
    Math.random() * Math.floor(siteSettings.backgroundCarousel.length)
  );

  const backGroundImage =
    siteSettings.backgroundCarousel[randomImage].asset.fluid;
  const backgroundAboveFooter =
    siteSettings.backgroundAboveFooter[randomImage].asset.fluid;

  const backgroundUrl = <Img fluid={backGroundImage} />;
  const backgroundAboveFooterUrl = <Img fluid={backgroundAboveFooter} />;

  const renderSectionItems = () => {
    if (sectionItems && sectionItems.length > 0) {
      return (
        <Container backgroundColor="white">
          <SectionItem className="headerItem">
            <div data-sal="zoom-in" data-sal-delay="100" data-sal-easing="ease">
              <h3>
                <ImArrowUpStyled /> {siteSettings.detailTitle}
              </h3>
            </div>

            <div
              data-sal="slide-up"
              data-sal-delay="400"
              data-sal-easing="ease"
            >
              <p>{siteSettings.detailText}</p>
            </div>
          </SectionItem>

          {sectionItems
            .sort((a, b) => a.order - b.order)
            .map((item) => (
              <SectionItem>
                <div
                  data-sal="slide-up"
                  data-sal-delay="400"
                  data-sal-easing="ease"
                >
                  <h4>
                    <FaBasketballBallStyled /> {item.title}
                  </h4>

                  <p>{item.text}</p>
                </div>
              </SectionItem>
            ))}
        </Container>
      );
    }
  };

  const renderHighlights = () => {
    if (highlightItems && highlightItems.length > 0) {
      return (
        <HighlightContainer>
          <h1>{siteSettings.highlightTitle}</h1>
          {highlightItems
            .sort((a, b) => a.order - b.order)
            .map((item) => (
              <HighlightCard
                title={item.title}
                text={item.text}
                imageObject={item.highlightImage.asset.fluid}
              />
            ))}
        </HighlightContainer>
      );
    }
  };

  const renderTestimonials = () => {
    if (testimonials && testimonials.length > 0) {
      return (
        <div>
          {testimonials.map((testimonial) => (
            <Testimonial
              name={testimonial.name}
              text={testimonial.text}
              photo={testimonial.personImage}
            />
          ))}
        </div>
      );
    }
  };

  const renderNotifications = () => {
    if (notifications && notifications.length > 0) {
      return (
        <div>
          {notifications.map((notification) => (
            <Notification notification={notification} />
          ))}
        </div>
      );
    }
  };

  return (
    <>
      <SEO />
      <HeadContainer>
        <HeadItem>
          <Img fluid={siteSettings.logo.asset.fluid} alt="logo" />
        </HeadItem>
        <HeadItem>
          <h2>{siteSettings.title}</h2>
        </HeadItem>
      </HeadContainer>

      <Container>
        <div>{renderNotifications()}</div>

        <SectionItem className="headerItem">
          <h3>{siteSettings.standoutTitle}</h3>
          <p>{siteSettings.standoutText}</p>

          <div className="centerItem">
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<SiInstagram />}
            >
              <a href="https://www.instagram.com/utahhoopsclub/">
                DM for details
              </a>
            </Button>
          </div>
        </SectionItem>

        <VideoContainer>
          <Video className="centerItem">
            <iframe
              title="Utah Hoops"
              src="https://player.vimeo.com/video/476078571"
              width="640"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </Video>
        </VideoContainer>
      </Container>

      {renderHighlights()}
      {renderSectionItems()}

      <ImageWrapper
        style={{
          backgroundImage: `url(${backgroundUrl.props.fluid.src})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      />

      <Container backgroundColor="lightGray">
        <SectionItem className="headerItem">
          <div data-sal="zoom-in" data-sal-delay="100" data-sal-easing="ease">
            <h3>
              <FaQuoteLeftStyled /> Testimonials
            </h3>
          </div>
        </SectionItem>

        {renderTestimonials()}
      </Container>

      <ImageWrapper
        style={{
          backgroundImage: `url(${backgroundAboveFooterUrl.props.fluid.src})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      />
    </>
  );
}

export const query = graphql`
  query SettingsQuery {
    settings: allSanitySiteSettings {
      nodes {
        title
        detailTitle
        detailText
        logo {
          asset {
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
        logoAlternative {
          asset {
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
        standoutTitle
        standoutText
        highlightTitle
        highlightText
        backgroundCarousel {
          asset {
            fluid(maxWidth: 4000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        backgroundAboveFooter {
          asset {
            fluid(maxWidth: 4000) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    sectionItems: allSanitySectionItem {
      nodes {
        title
        text
        order
      }
    }
    testimonials: allSanityTestimonial {
      nodes {
        name
        text
        personImage {
          asset {
            fluid(maxWidth: 80) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    highlightItems: allSanityHighlightItem {
      nodes {
        title
        text
        order
        highlightImage {
          asset {
            fluid(maxWidth: 1600) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    notifications: allSanityNotification {
      nodes {
        name
        text
      }
    }
  }
`;
