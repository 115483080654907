import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import styled from 'styled-components';

import React from 'react';

const CardStyled = styled(Card)`
  margin: 30px;
  padding: 15px;

  @media (min-width: 600px) {
    margin: 30px;
    padding: 20px;
  }
`;

const Testimonial = ({ name, text, photo }) => (
  <div data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease">
    <CardStyled>
      <Avatar alt={name} src={photo.asset.fluid.src} />
      <p>{text}</p>
      <p>-- {name}</p>
    </CardStyled>
  </div>
);

export default Testimonial;
