import styled from 'styled-components';
import React from 'react';

const StyledNotification = styled.div`
  padding: 100px;
  background-color: rgb(253, 236, 234);
  color: rgb(97, 26, 21);
  white-space: pre-wrap;
  margin-bottom: 30px;
  border: 1px solid red;

  h2 {
    font-size: 4rem;
  }

  a {
    color: #155fcc;
  }
`;

const Notification = ({ notification }) => (
  <StyledNotification>
    <h2>{notification.name}</h2>
    <p dangerouslySetInnerHTML={{ __html: notification.text }} />
  </StyledNotification>
);

export default Notification;
