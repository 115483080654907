import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const HighlightItem = styled.div`
  white-space: pre-wrap;
  background-color: var(--white);
  border-radius: 25px;

  width: 95%;
  margin: 25px 0;

  @media (min-width: 600px) {
    margin: 50px 0;
  }
`;

const HighlightItemHeader = styled.div`
  text-align: center;
  flex-grow: 1;
  padding-top: 20px;
  font-size: 3rem;
  font-weight: 900;
`;

const HighlightItemText = styled.div`
  margin: 0 20px;
  color: var(--grey);
`;

const HighlightCard = (props) => {
  const { title, text, imageObject } = props;
  return (
    <HighlightItem className="bottomRightShadow">
      <Img className="curvedImage" fluid={imageObject} />

      <div data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease">
        <HighlightItemHeader>{title}</HighlightItemHeader>
      </div>
      <HighlightItemText>
        <div data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease">
          <p>{text}</p>
        </div>
      </HighlightItemText>
    </HighlightItem>
  );
};

export default HighlightCard;
