import React from 'react';
import styled from 'styled-components';

const SectionContainer = styled.div`
  border-top: 1px solid var(--black);
  background: ${(props) =>
    (props.backgroundColor === 'primary' && 'var(--primaryBackground)') ||
    (props.backgroundColor === 'secondary' && 'var(--secondaryBackground)') ||
    props.backgroundColor};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 40px 20px;

  h1 {
    color: var(--white);
  }

  @media (min-width: 600px) {
    padding: 80px 110px;
  }
`;

const Container = ({ backgroundColor, children }) => (  
  <SectionContainer backgroundColor={backgroundColor}>
    {children}
  </SectionContainer>
);
export default Container;
